export default {
  acsc_person: '/visitor/person',
  acsc_visitor_appointment_record: '/visitor/reservationRecord',
  acsc_permission_group: '/visitor/visitArea',
  acsc_notes_for_admission: '/visitor/notice',
  // acsc_appointment_field: '/visitor/reservationField', // 预约字段不可编辑
  acsc_appointment_field: '/visitor/editReservationField', // 预约字段可编辑
  acsc_visitor_wechat_config: '/visitor/configure',
  acsc_system_proxy_config: '/visitor/proxySetting',
  acsc_appointment_reson: '/visitor/visitReason',
  acsc_visitor_type: '/visitor/visitorType',
  acsc_iner_person: '/visitor/person',
  acsc_outer_visitor: '/visitor/visitor',
  acsc_enterprise_info: '/visitor/firmName',
  hikkan_acscperson_info: '/personnel/personInfo',
  hikkan_acscperson_group: '/personnel/groups',
  hikkan_acscperson_visitor: '/personnel/visicor1',
  hikkan_acscperson_outer: '/personnel/external',
  // acsc_mobile_regester: '/personnel/groups',
  hikkan_acscperson_config: '/personnel/configuration',
  acsc_hdl_epidemic_prevention: '/visitor/policy' // 防疫政策
}

export const MENU_ICON = {
  acsc_visitor_appointment_record: '预约',
  acsc_visitor_appointment_param: '出入事件',
  acsc_visitor_wechat_config: '网络配置',
  acsc_notes_for_admission: '出入事件',
  hikkan_user: '人员组',
  hikkan_data_log: '处理',
  acsc_system_proxy_config: '设置',
  hikkan_personal: '人员',
  acsc_iner_person: '人员',
  acsc_outer_visitor: 'face',
  hikkan_acscperson_info: '人员',
  hikkan_acscperson_group: '人员组',
  hikkan_acscperson_visitor: '人员',
  hikkan_acscperson_outer: '人员',
  acsc_mobile_regester: '人员',
  hikkan_acscperson_config: '设置',
  acsc_enterprise_info: '企业名称',
  acsc_hdl_epidemic_prevention: '出入事件'
}

export const YUNYAO_MENU_PATH = {
  hikkan_user_list: '/user/management',
  hikkan_user_dept: '/user/department',
  hikkan_user_role: '/user/role',
  hikkan_data_log: '/dc/log',
  hikkan_personal_info: '/personal/info'
}

export const RENYUAN_MENU_PATH = {
  hikkan_acscperson_info: '/personnel/personInfo',
  hikkan_acscperson_group: '/groups',
  hikkan_acscperson_visitor: '/visitor',
  hikkan_acscperson_outer: '/external',
  hikkan_acscperson_config: '/configuration'
}
