<template>
  <Header>
    <template #right>
      <div class="wrap">
        <div class="top-tabs" v-if="$route.path!=='/portal/resetPwd'">
          <TopTabs />
        </div>
        <div class="top-actions">
          <TopActions />
        </div>
      </div>
    </template>
  </Header>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import TopTabs from './TopTabs.vue'
import TopActions from './TopActions.vue'

export default {
  components: { Header, TopTabs, TopActions },

  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  align-items: center;

  .top-tabs {
    flex: 1 0 auto;
  }

  .top-actions {
    flex: 0 0 auto;
  }
}
</style>
