<template>
  <el-menu ref="menu" @select="handleSelect" :default-active="currentIndex" class="portal-menu" theme="dark">
    <template v-for="menu in menus">
      <el-submenu
        v-if="menu.children.length"
        class="l1-menugroup"
        :key="menu.label"
        :index="menu.id"
        :icon="menu.icon"
        :title="menu.label"
      >
        <template #icon>
          <i :class="`svg-nav-icon-${MENU_ICON[menu.code]} nav-icon`"></i>
        </template>
        <el-menu-item
          class="l2-menu"
          v-for="subMenu in menu.children"
          :key="subMenu.label"
          :index="subMenu.id"
        >
          {{subMenu.label}}
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        v-else
        class="l1-menu"
        :key="menu.label"
        :index="menu.id"
      >
        <template #icon="">
          <i :class="`svg-nav-icon-${MENU_ICON[menu.code]} nav-icon`"></i>
        </template>
        {{menu.label}}
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { MENU_ICON } from '@/menus'

export default {
  data () {
    return {
      MENU_ICON
    }
  },

  computed: {
    ...mapState('navigation', ['currentIndex']),
    ...mapGetters('navigation', ['currentTab']),

    menus () {
      return this.currentTab.children || []
    }
  },

  methods: {
    ...mapMutations('navigation', ['setCurrentIndex']),

    handleSelect (event) {
      this.setCurrentIndex(event)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-icon {
  vertical-align: 10px!important;
}
</style>

<style lang="scss">
.portal-menu {
  height: 100%;
  background: #f5f7f9;
  &.el-menu {
    &>.l1-menu {
      .el-menu-item--text {
        color: #666666;
      }
      &.is-active {
        background: #e1eaf9;
        &>.el-menu-item--text {
          color: #0059FF;
        }
      }
      &:hover {
        background: #e1eaf9;
      }
    }

    &>.l1-menugroup {
      &>.el-submenu__title {
        &>.el-submenu__title--text {
          margin-left: 4px;
          color: #666666;
        }
      }
      &.is-opened>.el-submenu__title {
        background-color: transparent;
      }
      &.is-active>.el-submenu__title {
        background: #e1eaf9;
        .el-submenu__title--text {
          color: #0059FF;
        }
        &::before {
          transform: scaleY(1);
        }
      }
      .el-menu-item:not(.is-disabled):hover {
        background: #f0f2f4;
      }

      .el-submenu__title {
        color: #999999;
      }
      .el-submenu__title:not(.is-disabled):hover {
        background: #e1eaf9;
      }
    }

    .l2-menu {
      background: #f0f2f4;
      .el-menu-item--text {
        color: #999999;
      }
      &.is-active {
        .el-menu-item--text {
          color: #333333;
        }
      }
      &.is-active::before {
        transform: scaleY(0.001)!important;
      }
    }
  }
}
</style>
