<template>
  <div class="top-tabs no-user-select">
    <div
      v-for="tab of tabs"
      class="top-tab"
      :class="{ active: currentTab.id === tab.id }"
      :key="tab.id"
      @click="handleSelect(tab)"
    >
      <div class="top-tab-inner">
        {{tab.label}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('navigation', ['tabs']),
    ...mapGetters('navigation', ['currentTab'])
  },

  methods: {
    ...mapMutations('navigation', ['setCurrentIndex']),

    handleSelect (tab) {
      if (this.$route.path !== '/portal/dashboard') {
        this.$router.replace('/portal/dashboard')// 点击头部的tab先回到首`页，将页面地址固定成首页
      }
      this.setCurrentIndex(tab.id)
      if (tab.children[0]) {
        if (tab.children[0].children[0]) {
          this.$nextTick(() => {
            this.setCurrentIndex(tab.children[0].children[0].id)
          })
        } else {
          this.$nextTick(() => {
            this.setCurrentIndex(tab.children[0].id)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-tabs {
  display: flex;
  flex-direction: row;
  margin-left: 100px;
  margin-right: 100px;
  cursor: pointer;
  color:rgba(255,255,255,.8);
  .top-tab {
    text-align: center;
    min-width: 90px;
    max-width: 120px;
    flex: 1 1 auto;
  }
  .top-tab.active {
    font-weight: bold;
    .top-tab-inner {
      display: inline-block;
      position: relative;
      color:rgba(255,255,255,1);
      &::before{
        display: block;
        width: 110%;
        height: 2px;
        border-radius: 2px;
        background: rgba(255,255,255,1);
        content: ' ';
        position: absolute;
        bottom: -15px;
      }
      &::after {
        content: "";
        height: 40px;
        position: absolute;
        bottom: -29px;
        left: 0;
        right: -7px;
        text-decoration: none;
        background-image: radial-gradient(50% 27%,#eee 0,hsla(0,0%,100%,0) 100%);
        opacity: .6;
      }
    }
  }
}
</style>
