export const MENU_TYPE = {
  YUNYAO_OUTLINK: 0
}

export const YUNYAO_PAGE_INDEX = {
  hikkan_user_list: '/sso?index=4',
  hikkan_user_dept: '/sso?index=6',
  hikkan_user_role: '/sso?index=5',
  hikkan_data_log: '/sso?index=2',
  hikkan_personal_info: '/sso?index=7'
}
