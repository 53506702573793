<template>
  <div class="framework">
    <div class="framework-header">
      <Header />
    </div>
    <main class="framework-main">
      <h-page-container v-if="currentTab.id === INDEX_KEY">
        <router-view />
      </h-page-container>
      <template v-else>
        <div class="menu-wrap" v-if="menus.length">
          <Menu />
        </div>
        <iframe ref="frame" :src="frameUrl" frameborder="0" @load="handleLoad" class="iframe"></iframe>
      </template>
    </main>
  </div>
</template>

<script>
import * as eitsApi from '@/api/eits'
import auth from '@/mixins/auth'
import Header from './Header/Header.vue'
import Menu from './Menu/Menu.vue'
import isDev from '@/utils/isDev'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { LOCAL_ADDRESS, INDEX_KEY, LOCAL_DEBUGGER_SIGN } from '@/constant'
import { MENU_TYPE, YUNYAO_PAGE_INDEX } from '@/enum'
import store from '@/store'
import menus, { YUNYAO_MENU_PATH } from '@/menus'

export default {
  mixins: [auth],

  components: {
    Header,
    Menu
  },

  data () {
    return {
      INDEX_KEY,
      url: '',
      path: '',
      isFirst: true
    }
  },

  computed: {
    ...mapState('GLOBAL', ['userInfo']),
    ...mapState('navigation', ['tabs', 'currentIndex', 'yunPlat']),
    ...mapGetters('navigation', ['currentTab', 'currentMenu', 'currentBreadcrumb']),

    frameUrl () {
      // if (this.currentTab.id === INDEX_KEY) return (isDev ? LOCAL_ADDRESS : '') + '/pms/guide'
      if (!this.currentMenu) return
      console.log('this.currentMenu: ', this.currentMenu)
      const arr = ['hikkan_acscperson_info', 'hikkan_acscperson_group', 'hikkan_acscperson_visitor', 'hikkan_acscperson_outer', 'acsc_mobile_regester', 'hikkan_acscperson_config']
      if (arr.includes(this.currentMenu.code)) {
        this.currentMenu.type = 1
      }
      if (this.currentMenu.type === MENU_TYPE.YUNYAO_OUTLINK) return this.yunPlat + YUNYAO_PAGE_INDEX[this.currentMenu.code]

      const url = menus[this.currentMenu.code]
      if (url) {
        return (isDev ? LOCAL_ADDRESS : '') + url + (isDev ? LOCAL_DEBUGGER_SIGN : '')
      } else {
        return ''
      }
    },

    menus () {
      return this.currentTab.children || []
    },

    isYunyaoOutlink () {
      if (!this.currentMenu) return false
      return this.currentMenu.type === MENU_TYPE.YUNYAO_OUTLINK
    }
  },

  methods: {
    ...mapMutations('navigation', ['setCurrentIndex']),
    ...mapMutations('GLOBAL', ['setUserInfo']),

    handleLoad () {
      if (this.isYunyaoOutlink) {
        const data = {
          type: 'settings',
          data: {
            token: localStorage.getItem('tk'),
            path: YUNYAO_MENU_PATH[this.currentMenu.code],
            crumbs: this.currentBreadcrumb,
            loginUrl: `${location.protocol}//${location.host}/login`,
            showMenu: false,
            productCode: this.userInfo.productCode
          }
        }
        this.$refs.frame.contentWindow.postMessage(data, this.frameUrl)
      }
    },

    async actionGetUserInfo () {
      try {
        const { data } = await eitsApi.getUserBasic()
        const { data: userInfo } = await eitsApi.getUserDetail({ userId: data.id })
        this.setUserInfo(userInfo)
      } catch (err) {
        if (err.data && err.data.code === '402') {
          const { data } = await eitsApi.getUser()
          this.setUserInfo(data)
        }
      }
    }
  },
  async created () {
    if (this.$route.path.includes('dashboard')) {
      this.actionGetUserInfo()
      await store.dispatch('navigation/fetchMenuInfo')
      await store.dispatch('navigation/fetchYunPlat')
      // 默认展示权限列表中第一个tab，如果tab中有子菜单，那么直接展示该子菜单，否则直接展示tab对应的页面
      const firstTab = this.tabs[0]
      if (firstTab.children && firstTab.children.length) {
        this.setCurrentIndex(firstTab.children[0].id)
      } else {
        this.setCurrentIndex(firstTab.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.framework {
  display: flex;
  height: 100%;
  flex-flow: nowrap column;
}

.framework-header {
  position: relative;
  z-index: 1;
}

.framework-main {
  display: flex;
  flex-grow: 1;
}

.iframe {
  width: 100%;
  height: 100%;
}

.menu-wrap {
  width: 250px;
  border-right: 1px solid #e8e8e8;
  flex: 0 0 auto;
}
</style>
