<template>
  <div class="top_actions">
    <el-dropdown placement="bottom-start" trigger="hover" @command="handleCommand" v-if="userInfo.userName">
      <span class="dropdown-link">
        {{userInfo.userName}}
        <i class="h-icon-angle_down"></i>
      </span>
      <el-dropdown-menu slot="dropdown" ref="dropmenu">
        <el-dropdown-item command="updatePwd">修改密码</el-dropdown-item>
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import * as eitsApi from '@/api/eits'
import { mapMutations, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('GLOBAL', ['userInfo'])
  },

  methods: {
    ...mapMutations('navigation', [
      'setCurrentIndex'
    ]),

    handleCommand (command) {
      switch (command) {
      case 'logout':
        this.$confirm('确认退出系统吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'question'
        }).then(() => {
          this.loginout()
        })
        break
      case 'goUserCenter':
        // 跳转到系统管理--》用户中心---》用户管理
        this.setCurrentIndex('hikkan_user_list')
        break
      case 'updatePwd':
        location.href = '/portal/updatePwd' // 这里不用$router.push，是因为在内嵌iframe的情况下会出现push无效的情况
        // this.$router.push('updatePwd')
        break
      }
    },

    loginout () {
      eitsApi.logout()
      localStorage.removeItem('tk')
      location.href = '/login'
    }
  }
}
</script>

<style lang="scss" scoped>
.top_actions {
  display: flex;
  align-items: center;
}

.dropdown-link {
  // color: rgba(0,0,0,0.7);
  color:white;
  cursor: pointer;
}

.h-icon-angle_down {
  vertical-align: middle;
    font-size: 16px;
}

.divider {
  width: 1px;
  height: 15px;
  background: rgba(226,230,238,1);
}
</style>
